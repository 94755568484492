// extracted by mini-css-extract-plugin
export var card = "wheelsCardSkeleton-module--card--SeaiC";
export var s1 = "wheelsCardSkeleton-module--s1--2+kQs";
export var s2 = "wheelsCardSkeleton-module--s2--L-OAS";
export var s3 = "wheelsCardSkeleton-module--s3--aJ6BX";
export var s4 = "wheelsCardSkeleton-module--s4--0CWvv";
export var image = "wheelsCardSkeleton-module--image--I2-TM";
export var s5 = "wheelsCardSkeleton-module--s5--C6O5y";
export var s6 = "wheelsCardSkeleton-module--s6--FLAfn";
export var buttons = "wheelsCardSkeleton-module--buttons--jqS5E";
export var sb = "wheelsCardSkeleton-module--sb--RjJ7p";
export var s7 = "wheelsCardSkeleton-module--s7--aApjj";