import React, { useState } from "react";
import Container from "../../components/container/container";
import * as StyleComponents from "./selectionMode.module.scss";
import SearchByVehicle from "../searchByVehicle/searchByVehicle";
import { SearchByTire } from "../searchByTire/searchByTire";
import { SearchByWheel } from "../searchByWheel/searchByWheel";
import carDesktop from "../../images/carDesktop.webp";
import carPhotoMobile from "../../images/carMobile.webp";

// markup
export const SelectionMode = () => {
  const [DispVehicle, setDispVehicle] = useState({ display: "inline" });
  const [DispWheels, setDispWheels] = useState({ display: "none" });
  const [DispTires, setDispTires] = useState({ display: "none" });
  const [Vbtn, setVbtn] = useState(StyleComponents.selected);
  const [Wbtn, setWbtn] = useState("");
  const [Tbtn, setTbtn] = useState("");
  function toggleVehicle() {
    setDispVehicle({ display: "inline" });
    setDispWheels({ display: "none" });
    setDispTires({ display: "none" });
    setVbtn(StyleComponents.selected);
    setTbtn("");
    setWbtn("");
  }
  function toggleTires() {
    setDispVehicle({ display: "none" });
    setDispTires({ display: "inline" });
    setDispWheels({ display: "none" });
    setTbtn(StyleComponents.selected);
    setVbtn("");
    setWbtn("");
  }
  function toggleWheels() {
    setDispVehicle({ display: "none" });
    setDispTires({ display: "none" });
    setDispWheels({ display: "inline" });
    setWbtn(StyleComponents.selected);
    setVbtn("");
    setTbtn("");
  }

  return (
    <Container>
      <picture>
        <source
          media="(max-width: 1200px)"
          type="image/jpeg"
          srcSet={carPhotoMobile}
        />
        <img
          src={carDesktop}
          alt="Jeep 4x4 canada offroad with big tires"
          className={StyleComponents.mainImage}
        />
      </picture>

      <ul className={StyleComponents.modeSelector}>
        <li className={Vbtn}>
          <button onClick={() => toggleVehicle()}>
            <small>Recherche par</small>
            <h3>véhicule</h3>
          </button>
        </li>
        <li className={Tbtn}>
          <button onClick={() => toggleTires()}>
            <small>Recherche par taille</small>
            <h3>Pneus / Tires</h3>
          </button>
        </li>
        <li className={Wbtn}>
          <button onClick={() => toggleWheels()}>
            <small>Recherche par taille</small>
            <h3>Jantes / Mags</h3>
          </button>
        </li>
      </ul>
      <div style={DispVehicle}>
        <SearchByVehicle />
      </div>
      <div style={DispTires}>
        <SearchByTire />
      </div>
      <div style={DispWheels}>
        <SearchByWheel />
      </div>
    </Container>
  );
};
