import React from "react";

//MUI
import Skeleton from "@mui/material/Skeleton";

//CSS
import * as StyleComponents from "./wheelsCardSkeleton.module.scss";

// Markup
const WheelsCardSkeleton = () => {
  return (
    <div className={StyleComponents.card}>
      <Skeleton variant="rectangular" className={StyleComponents.s1} />
      <Skeleton variant="rectangular" className={StyleComponents.s2} />
      <Skeleton variant="rectangular" className={StyleComponents.s3} />
      <Skeleton variant="rectangular" className={StyleComponents.s4} />
      <Skeleton variant="rectangular" className={StyleComponents.image} />
      <Skeleton variant="rectangular" className={StyleComponents.s5} />
      <Skeleton variant="rectangular" className={StyleComponents.s6} />
      <div className={StyleComponents.buttons}>
        <Skeleton variant="rectangular" className={StyleComponents.sb} />
        <Skeleton variant="rectangular" className={StyleComponents.sb} />
        <Skeleton variant="rectangular" className={StyleComponents.sb} />
      </div>
      <Skeleton variant="rectangular" className={StyleComponents.s7} />
    </div>
  );
};

export default WheelsCardSkeleton;
