// set filters local storage
export function setFilters(params) {
  let f = JSON.stringify(params);
  localStorage.setItem("COfilters", f);
}

//get filters local storage
export function getFilters() {
  if (localStorage.getItem("COfilters") !== null) {
    let x = localStorage.getItem("COfilters");
    return JSON.parse(x);
  } else {
    return [];
  }
}
