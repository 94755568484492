// set vehicle local storage
export function setVehicle(params) {
  let v = JSON.stringify(params);
  localStorage.setItem("COvehicle", v);
}

//get vehicle local storage
export function getVehicle() {
  if (localStorage.getItem("COvehicle") !== null) {
    let x = localStorage.getItem("COvehicle");
    return JSON.parse(x);
  } else {
    return [];
  }
}

//set years local storage
export function setYearsLS(params) {
  let v = JSON.stringify(params);
  localStorage.setItem("COyears", v);
}

//get years local storage
export function getYearsLS() {
  if (localStorage.getItem("COyears") !== null) {
    let x = localStorage.getItem("COyears");
    return JSON.parse(x);
  } else {
    return [];
  }
}

//set models local storage
export function setModelsLS(params) {
  let v = JSON.stringify(params);
  localStorage.setItem("COmodels", v);
}

//get models local storage
export function getModelsLS() {
  if (localStorage.getItem("COmodels") !== null) {
    let x = localStorage.getItem("COmodels");
    return JSON.parse(x);
  } else {
    return [];
  }
}

//set trims local storage
export function setTrimsLS(params) {
  let v = JSON.stringify(params);
  localStorage.setItem("COtrims", v);
}

//get trims local storage
export function getTrimsLS() {
  if (localStorage.getItem("COtrims") !== null) {
    let x = localStorage.getItem("COtrims");
    return JSON.parse(x);
  } else {
    return [];
  }
}

//set options local storage
export function setOptionsLS(params) {
  let v = JSON.stringify(params);
  localStorage.setItem("COoptions", v);
}

//get options local storage
export function getOptionsLS() {
  if (localStorage.getItem("COoptions") !== null) {
    let x = localStorage.getItem("COoptions");
    return JSON.parse(x);
  } else {
    return [];
  }
}

//get displayed wheels local storage
export function getDispWheelsLS() {
  if (localStorage.getItem("COdispwheels") !== null) {
    let x = localStorage.getItem("COdispwheels");
    return JSON.parse(x);
  } else {
    return [];
  }
}

//set displayed wheels local storage
export function setDispWheelsLS(params) {
  let v = JSON.stringify(params);
  localStorage.setItem("COdispwheels", v);
}

//get total pages local storage
export function getTotalPagesLS() {
  if (localStorage.getItem("COtotalpages") !== null) {
    let x = localStorage.getItem("COtotalpages");
    return JSON.parse(x);
  } else {
    return [];
  }
}

//set total pages local storage
export function setTotalPagesLS(params) {
  let v = JSON.stringify(params);
  localStorage.setItem("COtotalpages", v);
}

//get total pages local storage
export function getFetchLS() {
  if (localStorage.getItem("COfetch") !== null) {
    let x = localStorage.getItem("COfetch");
    return JSON.parse(x);
  } else {
    return [];
  }
}

//set total pages local storage
export function setFetchLS(params) {
  let v = JSON.stringify(params);
  localStorage.setItem("COfetch", v);
}

//get total wheels local storage
export function getTotalWheelsLS() {
  if (localStorage.getItem("COtw") !== null) {
    let x = localStorage.getItem("COtw");
    return parseInt(x);
  } else {
    return 0;
  }
}

//set total wheels local storage
export function setTotalWheelsLS(params) {
  let v = JSON.stringify(params);
  localStorage.setItem("COtw", v);
}
