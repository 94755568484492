import React, { useState } from "react";
import TireCard from "../productCard/tireCard/tireCard";
import * as StyledComponents from "./serachByTire.module.scss";

//data
import data from "../../data/tireSize3.json";

// Import querry string library
var qs = require("qs");

// Markup
export const SearchByTire = () => {
  const [Width, setWidth] = useState();
  const [Hight, setHight] = useState();
  const [AvalHight, setAvalHight] = useState([]);
  const [Diameter, setDiameter] = useState();
  const [AvalDiameter, setAvalDiameter] = useState([]);
  const [Season, setSeason] = useState();
  const [AvalibleSizes, setAvalibleSizes] = useState([]);
  const [XSeason, setXSeason] = useState([false, "summer"]);
  const [DeleteMessage, setDeleteMessage] = useState();

  //handle the form sublit
  const handleSubmit = (e) => {
    e.preventDefault();
    searchTire();
  };

  async function searchTire() {
    //console.log(Width);
    //console.log(Hight);
    //console.log(Diameter);
    //console.log(XSeason);
    const query = qs.stringify({
      _where: [
        { sectionWidth: JSON.stringify(parseFloat(Width)) },
        { aspectRatio: JSON.stringify(parseFloat(Hight)) },
        { diameter: JSON.stringify(parseFloat(Diameter)) },
        /*AND*/
        { _or: [{ qwinter: true }, /*OR*/ { qwinter: XSeason[0] }] },
        /*AND*/
        { _or: [{ season: "4season" }, /*OR*/ { season: XSeason[1] }] },
        /*AND*/
        {
          _or: [
            { montreal_gte: 4 },
            /*OR*/ { toronto_gte: 4 },
            /*OR*/ { vancouver_gte: 4 },
          ],
        },
      ],
    });
    await fetch(
      `https://www.wheels-api.com/tires?${query}&_sort=price:ASC&_limit=-1`
    )
      .then((res) => res.json())
      .then(
        (result) => {
          //console.log(result);
          if (result.length === 0) {
            setDeleteMessage("AUCUN RÉSULTAT TROUVÉ");
          } else {
            setDeleteMessage("");
          }
          setAvalibleSizes(result);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  //change width
  function changeWidth(givenWidth) {
    setWidth(givenWidth);
    //Get data
    for (let i = 0; i < data.length; i++) {
      if (data[i].sectionWidth === parseFloat(givenWidth)) {
        //console.log(data[i].ratios);
        setAvalHight(data[i].ratios);
      }
    }
  }

  //change hight
  function changeHight(givenHight) {
    setHight(givenHight);
    //Get data
    for (let i = 0; i < AvalHight.length; i++) {
      if (AvalHight[i].aspectRatio === parseFloat(givenHight)) {
        //console.log(AvalHight[i].diameter);
        setAvalDiameter(AvalHight[i].diameter);
      }
    }
  }

  //change season
  function changeSeason(givenSeason) {
    setSeason(givenSeason);
    //console.log(givenSeason);
    if (givenSeason === "summer") {
      setXSeason([false, "summer"]);
    }
    if (givenSeason === "winter") {
      setXSeason([true, "winter"]);
    }
  }

  return (
    <>
      <form
        className={StyledComponents.form}
        onSubmit={(event) => {
          handleSubmit(event);
        }}
      >
        <h2>Sélectionnez votre taille de pneu</h2>
        <div className={StyledComponents.formControls}>
          {/*WIDTH*/}
          <select value={Width} onChange={(e) => changeWidth(e.target.value)}>
            <option selected hidden>
              Largeur
            </option>
            {data.map((item) => {
              return (
                <option key={item.sectionWidth} value={item.sectionWidth}>
                  {item.sectionWidth}
                </option>
              );
            })}
          </select>
          {/*HIGHT*/}
          <select value={Hight} onChange={(e) => changeHight(e.target.value)}>
            <option selected hidden>
              Hauteur
            </option>
            {AvalHight.map((item) => {
              return (
                <option key={item.aspectRatio} value={item.aspectRatio}>
                  {item.aspectRatio}
                </option>
              );
            })}
          </select>
          {/*DIAMETER*/}
          <select
            value={Diameter}
            onChange={(e) => setDiameter(e.target.value)}
          >
            <option selected hidden>
              Diamètre
            </option>
            {AvalDiameter.map((item) => {
              return (
                <option key={item} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
          {/*DIAMETER*/}
          <select value={Season} onChange={(e) => changeSeason(e.target.value)}>
            <option selected hidden>
              Saison
            </option>
            <option value={"summer"}>ÉTÉ</option>
            <option value={"winter"}>HIVER</option>
          </select>
          {/*SEND*/}
          <button type="submit">Rechercher</button>
        </div>
      </form>
      <div className={StyledComponents.itemsDisplay}>
        <h2>{DeleteMessage}</h2>
        {AvalibleSizes.map((item) => {
          if (AvalibleSizes.length !== 0) {
            return (
              <TireCard
                image={item.image}
                sku={item.sku}
                brand={item.brand}
                model={item.model}
                price={item.price}
                width={item.sectionWidth}
                aspect={item.aspectRatio}
                diam={item.diameter}
                season={item.season}
                ply={item.ply}
                lt={item.lt}
                loadRange={item.loadrange}
                psi={item.psi}
                qwinter={item.qwinter}
              />
            );
          }
          return <></>;
        })}
      </div>
    </>
  );
};
